$(function() {
    
  $('.associados .card ul').addClass('list-group list-group-flush text-center');
  $('.associados .card ul li').addClass('list-group-item');
  $('.associados .card ul li a').addClass('btn custom-btn');
  $('.parcerios .card-text a').addClass('btn btn-success btn-sm mt-2');
    
  $('.form-ajax').ajaxForm({
      dataType: 'json',
      beforeSubmit: function (arr, $form, options) {
        $('.submit-action').attr('disabled', 'disabled');
        startLoading();
      },
      success: function (json, status, xhr, form) {
        var id_form = form[0].id;

        if (json.erro != "") {
          alertify.alert(json.erro);
        } else if(json.url) {
            sendGAEvent('generate_lead', 'lead', id_form);
            adtracker(); //remover isso caso cliente configure o /obrigado para lançar um evento
            window.location.href = json.url;
        }else{
          alertify.alert(json.sucesso);
          $('#' + id_form)[0].reset();
        }
        adtracker();
        sendGAEvent('generate_lead', 'lead', id_form);
      },
      error: function () {
        alertify.alert("Erro ao processar formulário.<br>Tente novamente mais tarde.");
      },
      complete: function () {
        $('.submit-action').removeAttr('disabled');
        stopLoading();
      }
    });

    $(".mask--ano").mask("9999");
    $(".mask--data").mask("99/99/9999");
    $(".mask--cep").mask("99999-999");
    $(".mask--cpf").mask("999.999.999-99");
    $(".mask--cnpj").mask("99.999.999/9999-99");
    $(".mask--placa").mask("aaa-9999");
    $('.mask--phone')
    .mask("(99)9999-9999?9", { placeholder: "" })
    .blur(function (event) {
      var target, phone, element;
      target = (event.currentTarget) ? event.currentTarget : event.srcElement;
      phone = target.value.replace(/\D/g, '');
      element = $(target);
      element.unmask();
      if(phone.length > 10) {
        element.mask("(99)99999-999?9", { placeholder: "" });
      } else {
        element.mask("(99)9999-9999?9", { placeholder: "" });
      }
    });
    $('.js-click-event').on('click', function(){
	  sendGAEvent('click', $(this).data('category'), $(this).data('label'));
    });
    $('.botao-tenho-interesse-lead').on('click', function(){
      $('html').animate({scrollTop: $("#cta-interesse").offset().top}, 800);
      $(this).fadeOut();
      return false;
    });

    $('.slider').slick({
        dots: false,
        arrows: true,
        autoplay: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true
    });

    $('.slider-associados').slick({
        dots: true,
        arrows: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptativeHeight:true,
        centerMode: true,
        responsive: [
       {
         breakpoint: 1200,
         settings: {
           slidesToShow: 4,
           slidesToScroll: 1
         }
       },
       {
         breakpoint: 480,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1
         }
       }
       ]
    });

    $('.item-maior-foto').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        fade: true,
        asNavFor: '.fotos-menores'
    });

    $('.fotos-menores').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.item-maior-foto',
        dots: false,
        centerMode: true,
        focusOnSelect: true
    });

    
    $(".clique-menu").click(function() {
        $(".menu").slideToggle();
    });
});


if($('.botao-tenho-interesse-lead').length > 0){
    document.addEventListener('scroll', function(e) {
        pageOffset = window.pageYOffset;
        ctaOffsetTop = parseInt($("#cta-interesse").offset().top);
        ctaOffsetBottom = parseInt($("#cta-interesse").height()) + ctaOffsetTop;
        if(
            (pageOffset >= ctaOffsetTop-300 && pageOffset < ctaOffsetBottom-400)
          ){
          $('.botao-tenho-interesse-lead').fadeOut();
        }else{
          $('.botao-tenho-interesse-lead').fadeIn();
        }
    });
}

function startLoading(){
	$('.loading-wrapper').stop(true, true).fadeIn();
}
function stopLoading(){
	$('.loading-wrapper').stop(true, true).fadeOut('fast');
}
function sendGAEvent(action, category, label){
    if(typeof gtag !== 'undefined'){
        gtag('event', action, { 'event_category': category, 'event_label': label });
    }
  }
